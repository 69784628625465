<template>
  <page-layout>
    <span slot="header-name">{{ userFormTitle }}</span>
    <div slot="content">
      <el-form
        v-loading="loading"
        :size="$vars.sizes.form"
        :label-position="$vars.forms.label_position"
        :labelWidth="$vars.forms.label_width"
        ref="form"
        :model="instance"
        :rules="rules"
      >
        <el-form-item>
          <objects-inline ref="userFace" target="user" :editable="true" :showId="true" :id="instance.id"></objects-inline>
        </el-form-item>

        <el-form-item :label="$tf('common.id')" v-if="instance.id">
          {{ instance.id }}
        </el-form-item>

        <el-form-item :label="$tf('common.name')" prop="real_name">
          <el-input :maxlength="80" v-model="instance.real_name"></el-input>
        </el-form-item>

        <el-form-item :label="$tf('common.login')" prop="name">
          <el-input :maxlength="80" autocomplete="off" v-model="instance.name"></el-input>
        </el-form-item>

        <el-form-item :label="$tf('common.password')" prop="password">
          <el-input type="password" autocomplete="off" v-model="instance.password"></el-input>
        </el-form-item>

        <el-form-item :label="$tf('common.confirm_password')" prop="confirm_password">
          <el-input type="password" v-model="instance.confirm_password"></el-input>
        </el-form-item>

        <el-form-item prop="primary_group">
          <slot name="label">
            <div class="cols">
              <div class="el-form-item is-required">
                <div class="el-form-item__label">{{ $tf('roles') }}</div>
              </div>
              <div class="flex-cell"></div>
              <div class="el-form-item__label" style="width: 8rem">{{ $tf('primary | role') }}</div>
              <div></div>
            </div>
          </slot>

          <div :key="role.id" v-for="(role, i) in userRoles" class="role-item cols" :class="{ 'table-row--strip': i % 2 }">
            <div>{{ role.name }}</div>
            <div class="flex-cell"></div>
            <div style="width: 8rem; text-align: center">
              <el-radio v-model="instance.primary_group" :label="role.id">&nbsp;</el-radio>
            </div>
            <div @click="() => deleteRoleHandler(role)" class="delete-icon">
              <el-tooltip class="tooltip-item" effect="dark" :content="$tf('delete')" placement="top" :open-delay="800">
                <i class="el-icon-close"></i>
              </el-tooltip>
            </div>
          </div>

          <el-select v-model="role" :placeholder="$tf('add | role')" filterable style="margin-top: 1rem">
            <el-option :key="role.id" v-for="role in roles" :value="role.id" :label="role.name"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item :label="$tf('common.comment')">
          <el-input type="textarea" v-model="instance.comment"></el-input>
        </el-form-item>

        <el-form-item :label="$tf('common.active')">
          <el-checkbox v-model="instance.active"></el-checkbox>
        </el-form-item>

        <el-alert v-if="error" :title="error" type="error" :closable="true" class="mar-v-2"></el-alert>
      </el-form>

      <div class="mar-top--large">
        <div class="certificate-management" v-if="!create && $store.state.config.plugins.cproauth">
          <div class="mar-bottom--0-5">
            {{ certificate ? $tf('cryptopro | certificate') + ': ' + certificate.thumbprint : $tf('cryptopro | certificate | not_set,,1') }}
          </div>
          <el-button type="info" :plain="true" @click="addCertificateHandler" :loading="$store.state.cryptopro.loading" v-if="!certificate || !isCurrentUser"
            >{{ $tf('add | certificate') }}
          </el-button>
          <confirm-button
            target="user"
            plain
            :loading="$store.state.cryptopro.loading"
            @confirm="deleteCertificateHandler"
            v-if="!isCurrentUser && certificate"
          >
          </confirm-button>
        </div>
        <el-button
          type="primary"
          :disabled="!instance.primary_group || (create ? $hasNoPermission('ffsecurity.add_user') : $hasNoPermission('ffsecurity.change_user'))"
          @click="submit"
        >
          <span v-if="create">{{ $tf(['common.create']) }}</span>
          <span v-else>{{ $tf(['common.update']) }}</span>
        </el-button>
        <el-button type="info" :plain="true" @click="cancelHandler">{{ $tf('common.back') }}</el-button>
        <div class="mar-top--large">
          <confirm-button target="user" size="mini" plain @confirm="deleteHandler" :disabled="$hasNoPermission('ffsecurity.delete_user')" v-if="!create">
          </confirm-button>
        </div>
      </div>
    </div>
  </page-layout>
</template>

<script>
import ConfirmButton from '../confirm/confirm-button';
import _ from '@/apps/common/lodash';
import { symbolAsciiRule } from '@/apps/common/validator';
import ObjectsInline from '@/components/objects/inline.vue';
import PageLayout from '@/components/page/layout';

let baseRules = {
  real_name: [
    {
      required: true,
      type: 'string',
      message: 'error.required.field',
      trigger: 'change'
    }
  ],
  name: [{ required: true, message: 'error.required.login', trigger: 'change' }, symbolAsciiRule],
  password: [
    { required: true, message: 'error.required.password', trigger: 'change' },
    { min: 6, message: 'error.minimum.password', trigger: 'change' },
    symbolAsciiRule
  ],
  confirm_password: [
    {
      required: true,
      message: 'error.required.password',
      trigger: 'change'
    },
    symbolAsciiRule
  ],
  primary_group: [
    {
      required: true,
      type: 'number',
      message: 'error.required.field',
      trigger: 'blur'
    }
  ]
};

let empty = {
  id: '',
  name: '',
  real_name: '',
  comment: '',
  primary_group: '',
  groups: [],
  active: true
};

export default {
  name: 'form-user',
  components: {
    ObjectsInline,
    ConfirmButton,
    PageLayout
  },
  props: ['open'],
  data: function () {
    const rules = this.appendPasswordsValidationRulesToFormRulesSet(baseRules);
    return {
      rules: this.$applyRuleMessages(rules),
      error: null,
      previousInstance: null,
      instance: _.cloneDeep(empty),
      loading: false,
      role: null,
      certificate: null
    };
  },
  watch: {
    role: function (v, p) {
      if (v) {
        this.$nextTick(() => {
          this.role = null;
          this.instance.groups.push(v);
          this.setDefaultPrimaryRole();
        });
      }
    },
    'instance.primary_group': function (v, p) {
      if (v && ~this.instance.groups.indexOf(v)) this.instance.groups.splice(this.instance.groups.indexOf(v), 1);
      if (v && p) this.instance.groups.push(p);
    }
  },
  computed: {
    userFormTitle() {
      return this.create ? this.$tf(['common.create', 'common.user,, 1']) : this.$tf(['common.edit', 'common.user,, 1']);
    },
    userRoles() {
      const itemIds = [].concat(this.instance.groups, this.instance.primary_group).filter((v) => v >= 1);
      return itemIds
        .sort((a, b) => a - b)
        .map((v) => this.$store.state.groups.items.find((i) => i.id === v))
        .filter((role) => role);
    },
    roles() {
      const itemIds = [].concat(this.instance.groups, this.instance.primary_group).filter((v) => v >= 1);
      return this.$store.state.groups.items.filter((i) => !~itemIds.indexOf(i.id));
    },
    create() {
      return !this.$route.params.id;
    },
    state: function () {
      return this.$store.state.users;
    },
    isCurrentUser: function () {
      return this.$route.params.id === 'me';
    }
  },
  created() {
    let id = this.instance.id;
    this.item = this.instance;
    this.tab = this.generalName;

    if (id) {
      this.$store
        .dispatch(this.state.Action.Get, { id: decodeURIComponent(id) })
        .then((v) => {
          this.item = v;
        })
        .catch((e) => {
          this.$notify({ duration: 0, message: this.$createElement('message-box', { props: { e } }) });
        });
    } else {
      this.edit = true;
    }
  },
  mounted() {
    this.loadFormContent(this.$route.params.id);
  },
  methods: {
    loadCertificate() {
      if (!this.$store.state.config.plugins.cproauth) return;
      const id = this.$route.params.id;
      this.$store
        .dispatch('getUserCertificate', { id })
        .then((v) => {
          this.certificate = v;
        })
        .catch((e) => {
          this.certificate = null;
        });
    },
    cancelHandler(e) {
      this.$router.backTo({ path: '/users/' });
    },
    deleteHandler(e) {
      this.$store
        .dispatch(this.state.Action.Delete, this.instance)
        .then((v) => {
          this.$notify({ type: 'success', message: this.$tf(['common.action', 'common.success']) });
          this.$router.push({ path: '/users/filter/ordering=id' });
        })
        .catch((e) => {
          this.$notify({ duration: 0, message: this.$createElement('message-box', { props: { e: e } }) });
        });
    },
    deleteRoleHandler(v) {
      if (this.instance.primary_group === v.id) {
        this.instance.primary_group = null;
        this.setDefaultPrimaryRole();
      } else {
        let index = this.instance.groups.findIndex((i) => i === v.id);
        this.instance.groups.splice(index, 1);
      }
    },
    setDefaultPrimaryRole() {
      if (!this.instance.primary_group) {
        this.$nextTick(() => (this.instance.primary_group = this.instance.groups.sort((a, b) => a - b).shift()));
      }
    },
    reset() {
      this.$refs.form.resetFields();
      this.instance = _.cloneDeep(empty);
    },
    deleteCertificateHandler() {
      this.$store.state.cryptopro.loading = true;
      this.$store
        .dispatch('deleteUserCertificate', this.instance)
        .then((v) => {
          this.$notify({ type: 'success', message: this.$tf('action | success') });
          this.loadCertificate();
        })
        .catch((e) => {
          this.$notify({ duration: 0, message: this.$createElement('message-box', { props: { e: e } }) });
        })
        .finally(() => {
          this.$store.state.cryptopro.loading = false;
        });
    },
    addCertificateHandler() {
      if (!this.$cryptopro) {
        this.$notify({ type: 'error', message: this.$tf('error.crypto_pro.plugin_install') });
        return;
      }

      if (!this.$cryptopro.hasPlugin) {
        this.$notify({ type: 'error', message: this.$tf('error.crypto_pro.plugin_install') });
        return;
      }

      this.$store
        .dispatch('getCertificates')
        .then((v) => {
          this.$store.state.dialog.cryptopro.enabled = true;
          this.$store.state.dialog.cryptopro.action = this.addSignCryptoPro.bind(this);
        })
        .catch((e) => {
          const errorItem = (e && e.message) || e;
          this.$notify({ duration: 0, message: this.$createElement('message-box', { props: { e: errorItem } }) });
        })
        .finally(() => {
          this.$store.state.cryptopro.loading = false;
        });
    },
    addSignCryptoPro() {
      this.$store.state.dialog.cryptopro.enabled = false;
      this.$store.state.cryptopro.loading = true;

      this.$store
        .dispatch(this.isCurrentUser ? 'setCurrentUserCertificate' : 'setUserCertificate', this.instance)
        .then((v) => {
          this.$notify({ type: 'success', message: this.$tf('action | success') });
          this.loadCertificate();
        })
        .catch((e) => {
          this.$notify({ duration: 0, message: this.$createElement('message-box', { props: { e: e } }) });
        })
        .finally(() => {
          this.$store.state.cryptopro.loading = false;
        });
    },
    submit() {
      let store = this.$store,
        Action = this.$store.state.users.Action,
        fields = ['id', 'name', 'real_name', 'groups', 'active', 'comment', 'primary_group'],
        instance = null;

      if (this.instance.password) fields.push('password', 'confirm_password');
      instance = _.pick(this.instance, fields);

      this.error = null;
      this.$refs.form.validate(validHandler.bind(this));

      function validHandler(valid) {
        if (!valid) return;
        let action = instance.id ? Action.Update : Action.Create;

        store
          .dispatch(action, instance)
          .then((v) => {
            this.$notify({ type: 'success', message: this.$tf(['common.action', 'common.success']) });
            this.instance.id = v.id;
            this.$router.push({ path: `/users/${this.isCurrentUser ? 'me' : v.id}/` });
          })
          .catch((e) => {
            this.$notify({ duration: 0, message: this.$createElement('message-box', { props: { e: e } }) });
          });
      }
    },
    appendPasswordsValidationRulesToFormRulesSet(baseRules) {
      baseRules = _.cloneDeep(baseRules);
      baseRules.password.push({
        validator: this.validateConfirmationWhenPasswordIsValid,
        trigger: 'change'
      });
      baseRules.confirm_password.push({
        validator: this.validatePasswordsEqualityWhenConfirmationIsValid,
        trigger: 'change'
      });
      return baseRules;
    },
    validateConfirmationWhenPasswordIsValid(_rule, _value, callback) {
      if (this.instance.confirm_password) {
        this.$refs.form.validateField('confirm_password');
      }
      callback();
    },
    validatePasswordsEqualityWhenConfirmationIsValid(_rule, value, callback) {
      if (value !== this.instance.password) {
        callback(this.$t('error.confirm.password'));
      } else {
        callback();
      }
    },
    loadFormContent(id) {
      this.loading = false;
      if (!this.create) {
        this.rules.password[0].required = false;
        this.rules.confirm_password[0].required = false;
      }
      this.instance = _.cloneDeep(empty);
      if (id) {
        this.loading = true;
        this.$store
          .dispatch(this.$store.state.users.Action.Get, { id: id })
          .then((v) => {
            this.instance = Object.assign({}, empty, v);
            this.loadCertificate();
          })
          .catch((e) => {
            this.$notify({ duration: 0, message: this.$createElement('message-box', { props: { e } }) });
          })
          .finally(() => {
            this.loading = false;
          });
      }
    }
  },
  beforeRouteUpdate(to, from, next) {
    this.loadFormContent(to.params.id);
    next();
  }
};
</script>
